import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Roles, Language } from "../../Configurations/Constants";
import { getUserRole, isExists } from "../../Helpers/Utils";
import Proposals from "./Matching/Proposals";
import { useTranslation } from "react-i18next";
import { getProposalsWithUserInfo } from "../../Services/MatchingTandem/MatchingService";
import { updateUserDetails } from "../../Store/signInSignUpSlice";
import { useErrorHandler } from "react-error-boundary";

const Dashboard = () => {
  let { user } = useSelector((state) => state.user);
  const userRole = getUserRole(user);
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const handleError = useErrorHandler();
  const browserLanguage =
    i18n.language === Language.Deutsch.Code
      ? Language.Deutsch.Name
      : Language.English.Name;

  const [reloadData, setReloadData] = useState(false);
  const handleReloding = (isReload) => {
    setReloadData(isReload);
  };
  const [proposalsDetails, setProposals] = React.useState({
    data: null,
    userStatus: null,
    questionLastPublishDate: null,
    questionLastSavedDate: null,
    isNoProposals: true,
    selectedLanguage: browserLanguage,
  });

  useEffect(() => {
    async function getProposals() {
      if (
        userRole !== Roles.Admin &&
        (proposalsDetails.data === null ||
          proposalsDetails.selectedLanguage !== browserLanguage ||
          reloadData)
      ) {
        const { email } = user;
        
        if (isExists(email)) {
          await getProposalsWithUserInfo(email)
            .then((result) => {
              if (result.isSuccess) {
                const objuserDetail = {
                  ...user,
                  language: browserLanguage,
                };
                setProposals({
                  ...proposalsDetails,
                  data: result.proposals,
                  isNoProposals: false,
                  selectedLanguage: browserLanguage,
                });
                handleReloding(false);
                dispatch(updateUserDetails(objuserDetail));
              }
            })
            .catch((error) => {
              handleError(error);
            });
        }
      }
    }
    
    getProposals();
  }, [browserLanguage, reloadData]);

  if (isExists(user)) {
    if (userRole === Roles.Both && isExists(proposalsDetails.data)) {
      return (
        <Proposals
          UserRoles={[Roles.Mentee, Roles.Mentor]}
          Proposals={proposalsDetails.data}
          SelectedLanguage={browserLanguage}
          handleReloding={handleReloding}
        />
      );
    } else if (isExists(proposalsDetails.data)) {
      return (
        <Proposals
          UserRoles={[userRole]}
          Proposals={proposalsDetails.data}
          SelectedLanguage={browserLanguage}
          handleReloding={handleReloding}
        />
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};
export default Dashboard;
