import React, { useEffect, useState, useRef } from "react";
import { Row } from "react-bootstrap";
import { Helmet } from 'react-helmet';
import {
  MessageBarTemplates,
  MessageTypes,
  Roles,
  ProposalStates,
  CommandType,
  TandemStates,
  MaxLimits,
  StatusByRole,
  Pages
} from "../../../Configurations/Constants";
import { getProposedUserProfile, acceptProposal, declineProposal } from "../../../Services/MatchingTandem/MatchingService";
import { InformationBar, MessageBar, Question, StickyWrapper } from "../../Common/HelperComponents";
import PopUp from '../../../Components/CustomControls/PopUp/PopUp';
import ScoreCard from "../../CustomControls/ScoreCard/ScoreCard";
import { useSelector } from "react-redux";
import { addSpaceAfterComma, getFormattedDate, isExists } from "../../../Helpers/Utils";
import { useTranslation } from "react-i18next";
import CustomAccordion from "../../CustomControls/CustomAccordion/CustomAccordion";
import CustomTextArea from '../../CustomControls/Inputs/CustomTextArea';
import NoMatchingProposalsYet from './NoMatchingProposals';
import NoProposalsFound from './NoProposalsFound';
import NoProfile from './NoProfile';
import TimerCard from "../../CustomControls/TimerCard/TimerCard";
import { useErrorHandler } from 'react-error-boundary';

const Proposals = (props) => {
  const { t: translate } = useTranslation();
  const handleError = useErrorHandler();
  const { user } = useSelector((state) => state.user);
  const { UserRoles, Proposals, SelectedLanguage, handleReloding } = props;
  const [formData, setFormData] = useState({
    proposals: null,
    menteeProfile: null,
    menteeCreationDate: null,
    mentorCreationDate: null,
    mentorProfile: null,
    proposalRemainingTime: null,
    selectedLang: SelectedLanguage
  });

  const [messageData, setMessageData] = React.useState({
    message: "",
    type: MessageTypes.warning,
    show: false,
  });

  const [isStartMatchingCalled, setIsStartMatchingCalled] = useState(false);
  const handleStartMatchingEvent = (val) => {
    setIsStartMatchingCalled(val)
  }

  const helperData = {
    Mentee: {
      key: "menteeProposal",
      email: "email",
      firstName: "firstName",
      lastName: "lastName",
      acceptBtnName: "Accept mentor",
      declinetBtnName: "Decline mentor",
      profile: "mentorProfile",
      accordion: useRef(null)
    },
    Mentor: {
      key: "mentorProposal",
      email: "email",
      firstName: "firstName",
      lastName: "lastName",
      acceptBtnName: "Accept mentee",
      declinetBtnName: "Decline mentee",
      profile: "menteeProfile",
      accordion: useRef(null)
    },
    userProfileSequence: ["city", "federalState", "country", "language",],
    status: {
      ACTIVE: { key: "ACTIVE", type: "warning" },
      MATCHED: { key: "MATCHED", type: "success" },
      DECLINED: { key: "DECLINED", type: "error" },
      CLOSED: { key: "CLOSED", type: "info" }
    }
  }

  useEffect(() => {
    if (formData.proposals === null ||
      formData.selectedLang !== SelectedLanguage ||
      formData.proposals !== Proposals) {
      fillFormdata(Proposals);
    }
    //Bug#800
    if (isStartMatchingCalled) {
      showOrHideMessage(true, translate('matching-nomatch-found-error-mentee'), MessageTypes.warning);
    }
  }, [SelectedLanguage, isStartMatchingCalled, Proposals]);

  const showOrHideMessage = (show, message, type) => {
    setMessageData({ message, type, show });
  };

  const fillFormdata = (proposals) => {
    showOrHideMessage(false);
    let promises = [];
    let menteeCreationDate = null;
    let mentorCreationDate = null;
    let profileDetails = {
      Mentee: null,
      Mentor: null
    }
    UserRoles.forEach(eachRole => {
      if (isExists(proposals)) {
        const oppsiteRole = eachRole === Roles.Mentee ? Roles.Mentor : Roles.Mentee;
        let proposalData = isExists(eachRole) ? proposals[helperData[eachRole].key] : [];
        if (isExists(proposalData)) {
          let creationDate = getFormattedDate(proposalData[0].tandemDate, SelectedLanguage);
          if (oppsiteRole === Roles.Mentee) {
            menteeCreationDate = creationDate;
          } else {
            mentorCreationDate = creationDate;
          }
          const hightScoreKey = Object.keys(proposalData).reduce((highest, next) => {
            return proposalData[highest].baseMatchRuleScore >= proposalData[next].baseMatchRuleScore ? highest : next;
          });
          if (isExists(hightScoreKey)) {
            profileDetails = {
              ...profileDetails,
              [oppsiteRole]: proposalData[hightScoreKey][helperData[eachRole].email]
            }
            promises.push(getProposedUserProfile(proposalData[hightScoreKey][helperData[eachRole].email], oppsiteRole, SelectedLanguage, proposalData[hightScoreKey].matchingTandemId));
          }
        }
      }
    });

    Promise.all(promises).then(result => {
      let menteeDetails = false;
      let mentorDetails = false;
      if (profileDetails.Mentee) {
        menteeDetails = result.find(item => item.email === profileDetails.Mentee);
      }
      if (profileDetails.Mentor) {
        mentorDetails = result.find(item => item.email === profileDetails.Mentor);
      }

      setFormData({
        ...formData,
        menteeProfile: isExists(menteeDetails) ? menteeDetails : false,
        mentorProfile: isExists(mentorDetails) ? mentorDetails : false,
        proposals,
        menteeCreationDate,
        mentorCreationDate,
        proposalRemainingTime: proposals.proposalRemainingTime,
        selectedLang: SelectedLanguage
      });
    })
      .catch(error => { handleError(error); });
  }

  const [showMenteeAcc, setMenteeAcc] = useState('0');
  const [showMentorAcc, setMentorAcc] = useState('0');
  const showProposedProfile = async (email, role, creationDate, matchingTandemId) => {
    let oppsiteRole = role === Roles.Mentee ? Roles.Mentor : Roles.Mentee;
    let formattedDate = getFormattedDate(creationDate, SelectedLanguage);
    getProposedUserProfile(email, oppsiteRole, SelectedLanguage, matchingTandemId)
      .then(response => {
        if (isExists(response)) {
          response.creationDate = formattedDate;
          role === Roles.Mentee ? setMenteeAcc("0") : setMentorAcc("0");
          if (oppsiteRole === Roles.Mentee) {
            setFormData({
              ...formData,
              menteeProfile: response
            });
          } else {
            setFormData({
              ...formData,
              mentorProfile: response
            });
          }
          helperData[role].accordion.current.scrollIntoView();
        }
      })
      .catch(error => { handleError(error); });
  }

  const handleDeclineReason = (reasonText) => {
    setDeclineReason(
      {
        text: reasonText.selectedOptions,
        newValue: reasonText.newValue,
        id: reasonText.newId,
        isValid: (reasonText.selectedOptions ? reasonText.selectedOptions.length < MaxLimits.DeclineReason : true)
      }
    );
  }

  const generateProposal = (role, key) => {
    return (
      <div key={key} className="m-top-41">
        <p className="p-20-m">{translate(MessageBarTemplates[role].matchingTandem.subline)}</p>
        <InformationBar
          info
          addons="m-top-18 m-bottom-69"
          message={translate(MessageBarTemplates[role].matchingTandem.info, { creationDate: getCreationDateByRole(role) })}
        />
        <Row>
          {getMatchingResults(role)}
        </Row>
        {getQuestionAnswers(formData[helperData[role].profile], getCreationDateByRole(role), role)}
        <PopUp show={confirmPopup.isDisplay}
          acceptName={confirmPopup.textBtn}
          rejectName={translate('Cancel')}
          sendResponse={getReponseFromPopUp}
          isActionEnabled={declineReason.isValid}
          questions={confirmPopup.textToDisplay} >
          {(confirmPopup.command === CommandType.Decline) &&
            <CustomTextArea
              simple
              selectedItems={handleDeclineReason}
              labelName={translate('matching-tandem-confirmation-comment')}
              textNameToCheck = {confirmPopup.textToDisplay}
              inputMaxlength={1000}
              allowExtraLength
              rows={8}
              customClasses="m-top-23"
              placeholder={translate("Please enter here…")}
              shouldCallApi={true}
              />
          }
        </PopUp>
      </div>
    )
  }

  const getTimerCard = (userProfile, role) => {
    const { proposalRemainingTime } = formData;
    if (isExists(proposalRemainingTime && isExists(role))) {
      const { menteeRemainingTime, mentorRemainingTime } = proposalRemainingTime;
      if (role === Roles.Mentee && isExists(menteeRemainingTime)) {
        return getTimerComp(menteeRemainingTime[0]);
      } else if (role === Roles.Mentor && isExists(mentorRemainingTime) && isExists(userProfile)) {
        const timerData = mentorRemainingTime.find(eachTimer => {
          return eachTimer.tandemId === userProfile.matchingTandemId;
        });
        if (isExists(timerData)) {
          return getTimerComp(timerData, true);
        }
      }
    }
    return null;
  }

  const getTimerComp = (timerData, isMini = false) => {
    if (isExists(timerData)) {
      const { remainingHours, remainingMinutes, tandemValidUntil } = timerData;
      return <TimerCard customClasses={"m-top-68 m-bottom-68"} hours={remainingHours} minutes={remainingMinutes} isMentee={true} endDate={getFormattedDate(tandemValidUntil, SelectedLanguage)} mini={isMini} />;
    }
    return null;
  }

  const getQuestionAnswers = (userProfile, creationDate, role) => {
    if (isExists(userProfile)) {
      return (
        <div className="m-bottom-200" ref={helperData[role].accordion}>
          {role === Roles.Mentee && getTimerCard(userProfile, role)}
          <CustomAccordion
            mixedStyle={false}
            heading="Account"
            activeKey={role === Roles.Mentee ? showMenteeAcc : showMentorAcc}
            setActiveKey={role === Roles.Mentee ? setMenteeAcc : setMentorAcc}
            accTitle={translate("matching-tandem-accordion-title", { creationDate: creationDate })}>
            <p className="p-30-m m-top-25 m-bottom-28"> {translate("matching-tandem-user-title", { firstName: userProfile.firstName })}</p>
            <div className="container-fluid nopadding ">
              <div className="main-body">
                <div className="row">
                  {/* Profile details Section Starts here*/}
                  <div className="col-lg-4">
                    <div className={`card profile-card-border `}>
                      <div className="card-body">
                        {/** Profile Detail Section Iterate this  */} 
                        {user.userStatuses[0].userStatus === "MATCHED" && 
                        <div>
                          <p className="p2-bold m-top-20">{translate("E-mail address")}:</p>
                          <span className="p-description-16">{addSpaceAfterComma(userProfile.email) || "-"}</span>
                        </div>
    }
                        <div>
                          <p className="p2-bold m-top-20">{translate("City")}:</p>
                          <span className="p-description-16">{addSpaceAfterComma(userProfile.city) || "-"}</span>
                        </div>

                        <div >
                          <p className="p2-bold m-top-16">{translate("Federal State")}:</p>
                          <span className="p-description-16">{userProfile.federalState || "-"}</span>
                        </div>

                        <div >
                          <p className="p2-bold m-top-16">{translate("Country")}:</p>
                          <span className="p-description-16">{userProfile.country || "-"} </span>
                        </div>

                        <div >
                          <p className="p2-bold m-top-16">{translate("Languages")}:</p>
                          <span className="p-description-16">{addSpaceAfterComma(userProfile.language) || "-"}</span>
                        </div>

                        <div >
                          <p className="p2-bold m-top-16">{translate("Gender")}:</p>
                          <span className="p-description-16">{translate(userProfile.gender) || "-"}</span>
                        </div>

                        <div >
                          <p className="p2-bold m-top-16">{translate("Professional position")}:</p>
                          <span className="p-description-16">{addSpaceAfterComma(userProfile.professionalPosition) || "-"}</span>
                        </div>

                        <div >
                          <p className="p2-bold m-top-16">{translate("Company / Employer")}:</p>
                          <span className="p-description-16">{addSpaceAfterComma(userProfile.company) || "-"}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 p-left-63">
                    {/** Question Answer section -> Iterate this  */}
                    {userProfile.questionnairesQuestionAnswersModel.map((eachQuestion, i) => (
                      <Question customClasses="m-bottom-40" questionNameClass="p2-bold-v2" key={i} questionName={eachQuestion.userQuestionText} helperText="" underline>
                        <div className="p-top-16">
                          <p className="p2-medium-light">{addSpaceAfterComma(eachQuestion.userAnswer) || "-"}</p>
                        </div>
                      </Question>
                    ))}

                    {userProfile.profileQuestionAnswersModel.map((eachQuestion, i, arr) => (
                      <Question customClasses="m-bottom-40" questionNameClass="p2-bold-v2" key={i} questionName={eachQuestion.userQuestionText} helperText="" underline={i < arr.length - 1}>
                        <div className="p-top-16">
                          <p className="p2-medium-light">{addSpaceAfterComma(eachQuestion.userAnswer) || "-"}</p>
                        </div>
                      </Question>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </CustomAccordion>
        </div>
      );
    }
  }

  const [confirmPopup, setConfirmPopup] = React.useState({
    isDisplay: false,
    textBtn: "",
    textToDisplay: [],
    payload: null,
    command: null,
  });
  const [declineReason, setDeclineReason] = useState({
    text: '',
    declineReasonValue: '',
    id: '',
    isValid: true
  });
  const getReponseFromPopUp = async (value) => {
    if((declineReason.newValue == '' || declineReason.newValue == undefined) && value == true && (confirmPopup.textBtn == 'Decline mentor' || confirmPopup.textBtn == 'Decline mentee' || confirmPopup.textBtn == 'Mentor*in ablehnen' || confirmPopup.textBtn == 'Mentee ablehnen')) {
      alert('Please select Decline Reason');
      return;
     }
    setConfirmPopup({
      ...confirmPopup,
      isDisplay: false
    });
    if (value) {
      if (confirmPopup.command === CommandType.Accept) {
        await acceptProposal(confirmPopup.payload)
          .then(res => {
            if (res && res.isSuccess) {
              handleReloding(true);
            } else if (isExists(res.errorMessage)
              && res.errorMessage === "TandemAlreadyClosed") {
              displayTandemClosedPopUp();
            }
          })
          .catch(error => { handleError(error); });
      }
      else if (confirmPopup.command === CommandType.Decline) {
        if(confirmPopup.textBtn == 'Decline mentor' || confirmPopup.textBtn == 'Decline mentee' || confirmPopup.textBtn == 'Mentor*in ablehnen' || confirmPopup.textBtn == 'Mentee ablehnen') {
          confirmPopup.payload.proposalSaveModel[0].comment = declineReason.text;
          confirmPopup.payload.proposalSaveModel[0].declineDropReason = declineReason.newValue;
          confirmPopup.payload.proposalSaveModel[0].declineReasonId = declineReason.id;
        }
        


        await declineProposal(confirmPopup.payload)
          .then(res => {
            if (res && res.isSuccess) {
              handleReloding(true);
            } else if (isExists(res.errorMessage) &&
              res.errorMessage === "TandemAlreadyClosed") {
              displayTandemClosedPopUp();
            }
          })
          .catch(error => { handleError(error); });
      }
    }
  }

  const displayTandemClosedPopUp = () => {
    setConfirmPopup({
      ...confirmPopup,
      isDisplay: true,
      textToDisplay: [translate("timer-expired-refresh")],
      textBtn: null,
      command: null
    });
  }

  const handleAcceptDecline = (command, payload) => {
    const textToDisplay = command === CommandType.Accept ?
      [translate(MessageBarTemplates[payload.role].matchingTandem.accept)] :
      [translate(MessageBarTemplates[payload.role].matchingTandem.decline)];

    const textBtn = command === CommandType.Accept ?
      translate(MessageBarTemplates[payload.role].matchingTandem.acceptBtnTxt) :
      translate(MessageBarTemplates[payload.role].matchingTandem.declineBtnTxt);

    setConfirmPopup({
      ...confirmPopup,
      isDisplay: true,
      textBtn,
      textToDisplay,
      payload,
      command
    });
  }

  const createAcceptPayload = (eachMatch, proposalSaveModelArr, matchingTandemSaveModelArr, role) => {
    let matchingTandemSaveModel = JSON.parse(JSON.stringify(matchingTandemSaveModelArr));
    let proposalSaveModel = JSON.parse(JSON.stringify(proposalSaveModelArr));
    let acceptedProposal = null;
    if (role === Roles.Mentee) {
      proposalSaveModel = proposalSaveModel.filter((item) => item.proposalId !== eachMatch.proposalId);
      acceptedProposal = proposalSaveModel.find(item => item.proposalId === eachMatch.pairedProposalId);
      if (acceptedProposal) {
        acceptedProposal.proposalState = ProposalStates.ACCEPTED;
      }
      //Close other matching tandems 1325
      if (isExists(matchingTandemSaveModel) && matchingTandemSaveModel.length > 1) {
        matchingTandemSaveModel.forEach(tandem => {
          if (tandem.matchingTandemId !== eachMatch.matchingTandemId) {
            tandem.tandemState = TandemStates.CLOSED;
          }
        })
      }
    }
    else {
      proposalSaveModel = proposalSaveModel.filter((item) => item.proposalId === eachMatch.proposalId);
      matchingTandemSaveModel = matchingTandemSaveModel.filter((item) => item.matchingTandemId === eachMatch.matchingTandemId);
    }

    const acceptObj = {
      email: user.email,
      role: role,
      pairedUserEmail: eachMatch[helperData[role].email],
      proposalSaveModel,
      matchingTandemSaveModel
    }
    return acceptObj;
  }

  const createDeclinePayload = (eachMatch, role) => {
    const proposalSaveModel = [{
      proposalId: role === Roles.Mentee ? eachMatch.pairedProposalId : eachMatch.proposalId,
      proposalState: ProposalStates.DECLINED,
      comment: declineReason.text
    }]
    const matchingTandemSaveModel = [{
      matchingTandemId: eachMatch.matchingTandemId,
      tandemState: TandemStates.DECLINED
    }]
    const declineObj = {
      email: user.email,
      role: role,
      pairedUserEmail: eachMatch[helperData[role].email],
      proposalSaveModel,
      matchingTandemSaveModel
    }
    return declineObj;
  }

  const getMatchingResults = (role) => {
    const { proposals } = formData;
    let scoreCards = [];
    let proposalSaveModel = [];
    let matchingTandemSaveModel = [];

    if (isExists(proposals[helperData[role].key])) {
      proposals[helperData[role].key].forEach((eachMatch, i) => {
        if (role === Roles.Mentee) {
          //Add both entries if Mentee (1 with pairedProposalId and 1 with proposalId)
          proposalSaveModel.push({
            proposalId: eachMatch.pairedProposalId,
            proposalState: ProposalStates.CLOSED
          })
          proposalSaveModel.push({
            proposalId: eachMatch.proposalId,
            proposalState: ProposalStates.CLOSED
          })
          matchingTandemSaveModel.push({
            matchingTandemId: eachMatch.matchingTandemId,
            tandemState: TandemStates.ACTIVE
          })
        }
        else {
          proposalSaveModel.push({
            proposalId: eachMatch.proposalId,
            proposalState: ProposalStates.ACCEPTED
          })
          matchingTandemSaveModel.push({
            matchingTandemId: eachMatch.matchingTandemId,
            tandemState: TandemStates.MATCHED
          })
        }
      });

      proposals[helperData[role].key].forEach((eachMatch, i) => {
        const acceptObj = createAcceptPayload(eachMatch, proposalSaveModel, matchingTandemSaveModel, role);
        const declineObj = createDeclinePayload(eachMatch, role);
        if (eachMatch.proposalState === ProposalStates.PROPOSED
          || eachMatch.proposalState === ProposalStates.ACCEPTED) {

          let matchedName = eachMatch.tandemState === TandemStates.MATCHED ? ProposalStates.MATCHED_PROPOSAL : ProposalStates.ACCEPTED_PROPOSAL;

          scoreCards.push(
            <ScoreCard
              MATCHED={eachMatch.proposalState === ProposalStates.ACCEPTED}
              matchedName={translate(matchedName)}
              key={i}
              customClasses={`m-right-55 ${eachMatch.proposalState === ProposalStates.ACCEPTED ? "m-bottom-68" : ""}`}
              headerName={translate(role === Roles.Mentee ? Roles.Mentor : Roles.Mentee)}
              status={helperData.status[eachMatch.tandemState].type}
              statusName={helperData.status[eachMatch.tandemState].key}
              username={eachMatch[helperData[role].firstName]}
              userImage={eachMatch.profileImage}
              points={eachMatch.baseMatchRuleScore}
              isManualMatching={Number(eachMatch.baseMatchRuleScore) < 1}
              pointsLabel={translate("points")}
              infoBtnName={translate("See profile")}
              acceptBtnName={translate(helperData[role].acceptBtnName)}
              declineBtnName={translate(helperData[role].declinetBtnName)}
              commonAction={handleAcceptDecline}
              infoBtnAction={() => showProposedProfile(eachMatch[helperData[role].email], role, eachMatch.tandemDate, eachMatch.matchingTandemId)}
              acceptPayload={acceptObj}
              declinePayload={declineObj}
            >
              {role === Roles.Mentor && getTimerCard(eachMatch, role)}
            </ScoreCard>
          )
        }
      });
    }
    return scoreCards;
  }

  const getCreationDateByRole = (role) => {
    return role === Roles.Mentee ? formData.mentorCreationDate : formData.menteeCreationDate;
  }

  const canLoadData = () => {
    return formData.proposals !== null && formData.menteeProfile !== null && formData.mentorProfile !== null;
  }

  //render based on below cases covered in US-526
  const renderProposal = (eachRole, i) => {
    let renderCase = [];
    if (isExists(user.userStatuses)) {
      const { userStatus, questionLastPublishDate, questionLastSavedDate } = user.userStatuses.find(statusElem => statusElem.role === eachRole);
      if (userStatus === StatusByRole.NEW) {
        if (eachRole === Roles.Mentor
          && isExists(questionLastSavedDate)
          && !isExists(questionLastPublishDate)) {
          renderCase.push(
            <NoMatchingProposalsYet
              key={i}
              Role={eachRole}
              Email={user.email}
              handleStartMatchingEvent={handleStartMatchingEvent}
              handleReloding={handleReloding} />);
        }
        else {
          renderCase.push(<NoProfile
            key={i}
            Role={eachRole}
            Email={user.email} />);
        }
      }
      else if (userStatus === StatusByRole.PENDING) {
        if (eachRole === Roles.Mentee) {
          renderCase.push(<NoMatchingProposalsYet
            key={i}
            Role={eachRole}
            Email={user.email}
            handleReloding={handleReloding}
            isPending={true} />);
        }
      }
      else if (userStatus === StatusByRole.AVAILABLE
        || userStatus === StatusByRole.PROPOSED) {
        if (eachRole === Roles.Mentee) {
          if (isExists(formData.proposals[helperData[eachRole].key])) {
            renderCase.push(generateProposal(eachRole, i));
          }
          else {
            if (isStartMatchingCalled) {
              renderCase.push(<NoProposalsFound
                key={i}
                Role={eachRole}
                Email={user.email} />);
            }
            else {
              renderCase.push(<NoMatchingProposalsYet
                key={i}
                Role={eachRole}
                Email={user.email}
                handleStartMatchingEvent={handleStartMatchingEvent}
                handleReloding={handleReloding} />);
            }
          }
        }
        else if (eachRole === Roles.Mentor) {
          if (isExists(formData.proposals[helperData[eachRole].key])) {
            renderCase.push(generateProposal(eachRole, i));
          }
          else {
            renderCase.push(<NoProposalsFound
              key={i}
              Role={eachRole}
              Email={user.email} />);
          }
        }
      }
      else if (userStatus === StatusByRole.PAUSED) {
        if (eachRole === Roles.Mentor) {
          //801
          if (isExists(questionLastPublishDate)) {
            renderCase.push(<NoProposalsFound
              key={i}
              Role={eachRole}
              Email={user.email} />);
          }
          else {
            renderCase.push(<NoMatchingProposalsYet
              key={i}
              Role={eachRole}
              Email={user.email}
              handleStartMatchingEvent={handleStartMatchingEvent}
              handleReloding={handleReloding} />);
          }
        }
      }
      else if (userStatus === StatusByRole.MATCHED) {
        if (isExists(formData.proposals[helperData[eachRole].key])) {
          renderCase.push(generateProposal(eachRole, i));
        }
      }
    }
    return renderCase;
  }

  return (
    <>
      <Helmet>
        <title>{translate(Pages.Dashboard.pageTitle)}</title>
      </Helmet>
      <StickyWrapper>
        <MessageBar {...messageData} />
      </StickyWrapper>
      {canLoadData() && (
        <div className="proposals-container occupy-1 flex center-center m-bottom-60 m-top-60">
          {UserRoles.map((eachRole, i) => {
            if (i > 0) {
              return (
                <div className="w-max app-standard-gap" key={i}>
                  {renderProposal(eachRole, i)}
                </div>
              );
            } else {
              return (
                <>
                  <div className="w-max app-standard-gap">
                    <p className="p1">{translate("matching-page-header")}</p>
                    {renderProposal(eachRole, i)}
                  </div>
                  <hr className="seperator" />
                </>
              );
            }
          })}
        </div>
      )}
    </>
  )
}

export default Proposals;